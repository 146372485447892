@font-face {
  font-family: 'OVOCircular';
  src: url('/fonts/OVOCircularWeb-Book.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'OVOCircular';
  src: url('/fonts/OVOCircularWeb-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'OVOCircular';
  src: url('/fonts/OVOCircularWeb-Black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}